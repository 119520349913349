<template>
	<div
		class="headerMain"
		:style="{ 'border-bottom': isHiddenMenu ? '2px solid #d1292e' : '' }"
	>
		<div class="header" v-if="!isHiddenMenu" @mouseleave.stop="showMenu(true)">
			<!-- @mouseleave.stop="showMenu(true)" -->
			<div class="headerlogo">
				<img @click="jumpTohome" src="../assets/img/homelogo.png" alt="" />
				<div class="logo"></div>
				<!-- suffix-icon="el-icon-search" -->
				<div class="searchinput">
					<div class="searchbox" v-if="showsearch === 0">
						<el-input
							class="el-input"
							v-model="input"
							@keydown.native="handleclick"
							placeholder="请输入要搜索的关键词"
						></el-input>
						<i class="iconfont icon-sousuo searchicon" @click.stop="search"></i>
					</div>

					<div class="search" @click="isShowinput" v-if="showsearch === 1">
						<i class="iconfont icon-sousuo"></i>
					</div>
				</div>
			</div>
			<ul class="classification">
				<!-- <li
       is="router-link"
       tag="li"
       v-for="item in list"
       :to="item.path"
       @mouseenter.native="overTag(item.id)"
       :key="item.id">
         {{item.title}}
        <div class="radis"></div>
       </li> -->
				<!-- trigger="click" -->
				<li v-for="(item, index) in list" :key="item.id">
					<el-dropdown
						:placement="'bottom'"
						@click.native="jumpTo(item, item.id)"
						@visible-change="changeVisible"
					>
						<span
							class="el-dropdown-link"
							@mouseenter="overTag(item.id)"
							@mouseleave="overhideTag()"
							@click="keepoverTag(item.id)"
							>{{ item.title }}</span
						>
						<div class="radis" v-show="tabnum2 == item.id"></div>
						<div class="radis2" v-show="tabnum == item.id"></div>
						<el-dropdown-menu
							slot="dropdown"
							@mouseleave.native="childmenu"
							v-if="item.childrenmenu"
						>
							<div
								style="width: 1200px;margin: 0 auto;display: flex;"
								:class="
									index < 3
										? 'd-start'
										: list.length > 6 && index > list.length - 3
										? 'd-end'
										: 'd-center'
								"
							>
								<el-dropdown-item
									v-for="(menus, index) in item.childrenmenu"
									:key="index"
									@click.native="jumpToChild(item, menus, item.id, index)"
									:style="
										jumpId == item.id && jumpIndex == index
											? 'color:#d1292e'
											: ''
									"
									>{{ menus.title }}</el-dropdown-item
								>
							</div>
						</el-dropdown-menu>
						<!-- 解决disable报错  -->
						<el-dropdown-menu
							style="background-color: rgba(0,0,0,0) !important;border: 0 !important;"
						></el-dropdown-menu>
					</el-dropdown>
				</li>
			</ul>
		</div>
		<div class="headerSimple" v-if="isHiddenMenu" @mouseenter="showMenu(false)">
			<img
				class="headerlogo"
				@click="jumpTohome"
				src="../assets/img/homelogo.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
import apiUrls from "@api";
export default {
	name: "Header",
	data() {
		return {
			isHiddenMenu: false, //是否显示菜单（判断网页滚动条）
			isHiddenTopShow: false, //滚动条不在顶部是否显示菜单
			scrollTop: 0,
			input: "",
			addbg: 0,
			showsearch: 1,
			tabnum: 1,
			tabnum2: 1,
			activeIndex: "1",
			jumpId: -1,
			jumpIndex: -1,

			list: [
				{
					id: 1,
					title: "首页",
					path: "/home",
				},
				{
					id: 2,
					title: "组织概况",
					// path: "/orgIntroduce",
					path: "",
					childrenmenu: [
						{
							title: "组织简介",
							path: "/orgIntroduce",
						},
						{
							title: "领导机构",
							path: "/bossIntro",
						},
						{
							title: "直属单位",
							path: "/underOrganization",
						},
						{
							title: "市州红十字会",
							path: "/organization",
						},
						/*{
							title: '四川红十字智库',
							path: '/smart'
						},*/
						{
							title: "大事记",
							path: "/big",
						},
					],
				},
				{
					id: 3,
					title: "应急救援",
					path: "",
					childrenmenu: [
						{
							title: "灾害管理",
							path: "/emergency",
						},
						{
							title: "备灾救灾",
							path: "/system",
						},
						{
							title: "救援队伍",
							path: "/team",
						},
						/*{
							title: '灾后重建',
							path: '/rebuild'
						}*/
					],
				},
				{
					id: 4,
					title: "人道救助",
					// path: "/humanhelp",
					path: "",
					childrenmenu: [
						{
							title: "救助指南",
							path: "/helpguide",
						},
						{
							title: "工作动态",
							path: "/helptrends",
						},
						{
							title: "彩票金先心病救助",
							path: "/angelsunfund",
						},
						{
							title: "彩票金白血病救助",
							path: "/angelfund",
						},
						{
							title: "救助行动",
							path: "/humanhelp",
						},
						{
							title: "博爱送万家",
							path: "/lovetoall",
						},
						{
							title: "救助公示",
							path: "/helppublic",
						},
						{
							title: '"三献"救助',
							path: "/sxhelppublic",
						},
						{
							title: "地震伤员康复及假肢服务项目",
							path: "/hongkong",
						},
					],
				},
				{
					id: 5,
					title: "应急救护",
					// path: "/rescue",
					path: "",
					childrenmenu: [
						{
							title: "应急救护简介",
							path: "/rescueIntroduce",
						},
						{
							title: "健康救护小知识",
							path: "/rescue",
						},
						// {
						// 	title: '生命健康',
						// 	path: '/lifeheath'
						// },
						{
							title: "救护服务简介",
							path: "/server",
						},
					],
				},
				{
					id: 6,
					title: "项目管理",
					path: "",
					childrenmenu: [
						// {
						// 	title: '项目简介',
						// 	path: '/humanitarian'
						// },
						{
							title: "执行管理",
							path: "/proActivity",
						},
						{
							title: "项目捐赠",
							path: "/proIntroduce",
						},
					],
				},
				{
					id: 7,
					title: "造血干细胞捐献",
					path: "",
					childrenmenu: [
						{
							title: "工作动态",
							path: "/stemcells",
						},
						{
							title: "四川省基本情况简介",
							path: "/sichuan",
						},
						{
							title: "造干志愿者风采",
							path: "/elegant",
						},
						{
							title: "科普知识",
							path: "/knowledge",
						},
						/*{
							title: '无偿献血',
							path: '/wcxx'
						}*/
					],
				},

				{
					id: 8,
					title: "器官/遗体/角膜捐献",
					path: "",
					childrenmenu: [
						{
							title: "工作动态",
							path: "/gzdt",
						},
						{
							title: "四川省基本情况简介",
							path: "/scsjbqkjj",
						},
						{
							title: "缅怀纪念",
							path: "/mhjl",
						},
						{
							title: "科普知识",
							path: "/kpzs",
						},
					],
				},
				{ id: 12, title: "无偿献血", path: "/wcxx" },
				{
					id: 9,
					title: "信息公开",
					path: "/infoopen",
				},
				{
					id: 10,
					title: "志愿服务与基层组织",
					// path: "/volunteer",
					path: "",
					childrenmenu: [
						{
							title: "红十字·志愿者",
							path: "/volunteer2",
						},
						{
							title: "红十字·青少年",
							path: "/volunteer3",
						},
						{
							title: "红十字·红丝带",
							path: "/volunteer",
						},
						{
							title: "南丁格尔志愿服务队",
							path: "/ldge",
							// node:"zyzfw_hdsp"
						},
						{
							title: "冠名医疗机构",
							path: "/gmyljg",
							// node:"zyzfw_hdsp"
						},
						{
							title: "基层组织",
							path: "/gmyljg2",
							// node:"zyzfw_hdsp"
						},
					],
				},
				{
					id: 11,
					title: "学习平台",
					// path: "/learnplatfrom",
					path: "",
					childrenmenu: [
						{
							title: "政策法规",
							path: "/lawknowledge",
						},
						{
							title: "规范性文件",
							path: "/business",
						},
						{
							title: "红十字运动知识",
							path: "/learnplatfrom",
						},
					],
				},
				{
					id: 13,
					title: "宣传资料库",
					// path: "/learnplatfrom",s
					path: "",
					childrenmenu: [
						{
							title: "图文资料",
							path: "/graphicAndTextual",
							node: "twzl"
						},
						{
							title: "视频资料",
							path: "/videos",
							node: "spzl"
						}
					],
				},
			],
		};
	},

	components: {},
	destroyed() {
		window.removeEventListener("scroll", this.checkScorll);
	},
	mounted() {
		let parentItem = JSON.parse(localStorage.getItem("parentItem")) || {};
		if (parentItem.childrenmenu) {
			this.jumpIndex = localStorage.getItem("jumpIndex");
			this.jumpId = parentItem.id;
		}
		this.tabnum = parentItem.id || 1;
		this.tabnum2 = parentItem.id || 1;
		window.addEventListener("scroll", this.checkScorll);
	},

	methods: {
		changeVisible(val) {
			if (this.scrollTop > 0 && val) {
				this.isHiddenTopShow = false;
			} else if (this.scrollTop > 0 && !val) {
				this.isHiddenMenu == false
					? (this.isHiddenMenu = false)
					: (this.isHiddenMenu = true);
				this.isHiddenTopShow = false;
			} else {
				this.isHiddenTopShow = false;
			}
		},
		showMenu(val) {
			if (this.scrollTop > 0 && this.isHiddenTopShow) {
				this.isHiddenMenu = val;
			} else {
				this.isHiddenMenu = false;
			}
		},
		checkScorll() {
			this.scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			this.scrollTop > 0
				? (this.isHiddenMenu = true)
				: (this.isHiddenMenu = false);
		},
		overTag(id) {
			this.tabnum = id;
			this.showMenu(false);
		},
		overhideTag() {
			this.tabnum = -1;
			this.showMenu(false);
		},
		childmenu() {
			this.showMenu(true);
		},
		keepoverTag(id) {
			this.tabnum2 = id;
		},
		isShowinput() {
			// this.addbg = 1;
			this.showsearch = 0;
		},
		/* hideinput() {
			// this.addbg = 0;
			this.showsearch = 1;
		}, */
		handleclick(e) {
			if (e.keyCode === 13) {
				this.search();
			}
		},
		search() {
			if (this.input !== "") {
				apiUrls.keyWords({ searchKeyword: this.input }).then((res) => {});
				this.$router.push({
					path: "/searchResult",
					query: { name: "搜索结果", keyWords: this.input },
				});
			}
		},
		jumpTo(menu, id) {
			// 如果有path 那就是一级菜单 没有 那就是二级菜单
			if (menu.path != "") {
				localStorage.setItem("parentItem", JSON.stringify(menu));
				this.jumpId = id;
				localStorage.removeItem("bread");
				this.$firstBread = true;
				let bread = [{ title: menu.title, path: menu.path }];
				localStorage.setItem("bread", JSON.stringify(bread));
				if (menu) {
					this.$router.push({ path: `${menu.path}` });
				} else {
					return;
				}
			}
		},
		jumpToChild(item, menus, id, index) {
			if ((this.jumpId = id)) {
				this.jumpIndex = index;
			}
			this.tabnum2 = id;
			localStorage.setItem("tabnum2", id);
			localStorage.setItem("parentItem", JSON.stringify(item));
			localStorage.setItem("jumpIndex", index);
			//console.log(item,menus)
			// this.tabnum = id;
			let bread = JSON.parse(localStorage.getItem("bread")) || [];
			bread[1] = null;
			bread[1] = { title: item.title, path: item.path };
			bread[2] = null;
			bread[2] = { title: menus.title, path: menus.path };
			localStorage.setItem("bread", JSON.stringify(bread));
			let node = menus.node ?? "";
			this.$goto(menus.path, { type: node });
		},
		jumpTohome() {
			this.$router.push({ path: "/home" });
		},
	},
};
</script>
<style lang="less" scoped>
.headerMain {
	background-color: #ffffff;
	width: 100%;
	position: fixed;
	z-index: 1000;
	top: 0px;
}
.header {
	width: 1200px;
	margin: 0 auto;
	height: 166px;
	// overflow: hidden;
	cursor: pointer;
	&::before {
		content: ".";
		display: block;
		height: 0;
		clear: both;
		overflow: hidden;
		visibility: hidden;
	}
	.headerlogo {
		height: 57px;
		margin-top: 36px;
		display: flex;
		justify-content: space-between;
		img {
			width: 273px;
			height: 59px;
			background-color: #ffffff;
		}
		// .logo {
		//   width: 273px;
		//   height: 59px;
		//   background-color: #ffffff;
		//   background: url('../assets/img/homelogo.png') no-repeat center center;
		// }
	}
	.searchinput {
		display: flex;
		align-items: center;
		border-radius: 20px;
		.searchbox {
			position: relative;
		}
		.searchicon {
			position: absolute;
			font-size: 25px;
			color: #d1292e;
			right: 11px;
			top: 8px;
		}
		.el-input {
			padding: 0 20px;
			padding-top: 5px;
			flex: 1;
			width: 250px;
			height: 30px;
			border: none;
			outline: none;
			border: 1px solid #d1292e;
			font-size: 14px;
			color: #838383;
			border-radius: 18px;
			/deep/ .el-input__inner {
				height: 28px !important;
				outline: none !important;
				border: 1px solid #ffffff !important;
			}
		}
	}
	.search {
		width: 40px;
		height: 40px;
		background-color: #d1292e;
		// margin-top: 16px;
		// margin-left: 10px;
		border-radius: 50%;
		.iconfont {
			&::before {
				position: relative;
				top: 9px;
				left: 8px;
				font-size: 24px;
				color: #ffffff;
			}
		}
	}
	.classification {
		// overflow: hidden;
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-top: 44px;
		font-size: 16px;
		li {
			height: 27px;
			position: relative;
			/* &:nth-child(1) {
        color: #D1292E;
        font-weight: bold;
        .radis{
        position: absolute;
        bottom: 0;
        height: 4px;
        width: 100%;
        background-color: #D1292E;
        border-radius: 2px;
      }
      }
      /deep/ .el-dropdown {
        width: 100%;
        height: 100%;
        .el-dropdown-link {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      } */
		}

		.radis,
		.radis2 {
			position: absolute;
			bottom: -13px;
			height: 4px;
			width: 100%;
			background-color: #d1292e;
			z-index: 999;
			border-radius: 2px;
		}
	}
	.optionmenu {
		position: absolute;
		width: 100%;
		top: 166px;
		left: 0px;
		li {
			width: 118px;
			line-height: 27px;
			color: #333333;
			text-align: center;
			position: absolute;
			background: #ffffff;
			display: none;
			dd {
				border: 1px dashed pink;
			}
			// border: 1px solid #666666;
			&:nth-child(1) {
				left: 183px;
			}
			&:nth-child(2) {
				right: 405px;
			}
		}
	}
}
.headerSimple {
	width: 1200px;
	margin: 0 auto;
	height: 45px;
	display: flex;
	justify-content: center;
	padding: 5px 0px;
	.headerlogo {
		height: 45px;
	}
}
</style>
<style scoped>
.el-dropdown-link {
	cursor: pointer;
	color: #333333;
	font-size: 16px;
}
.d-start {
	justify-content: flex-start;
}
.d-center {
	justify-content: center;
}
.d-end {
	justify-content: flex-end;
}
.el-dropdown-menu {
	width: 100vw !important;
	position: fixed !important;
	top: 154px !important;
	border-top: 1px solid #f5f5f5 !important;
	box-shadow: none !important;
}
.el-dropdown-menu__item:hover {
	background-color: #fff !important;
	color: #d1292e !important;
}
/* #isShow {
  display: none !important;
} */
.el-icon-arrow-down {
	font-size: 12px;
	text-align: center;
}
</style>
